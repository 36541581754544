import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import GridField from './GridField';
import UploadField from './UploadField';

const QuestionFields = ({ question, formik, readOnly }) => {
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  // category
  // section
  // sort order
  // question text
  // help text
  // field types: single-line text, multi-line text, yes/no, select, numeric, grid (specify columns,  default rows), file upload box
  // options: required, blank option (for selects)
  let showField = true;
  if (question.conditionals) {
    showField = question.conditionals.reduce((carry, conditional) => {
      if (!carry) {
        return false; // TODO: support OR conditionals
      }
      switch (conditional.evaluator) {
        case 'eq':
        default:
          return formik.values[conditional.field] === conditional.value;
      }
    }, showField);
  }

  const commonFieldProps = {
    id: question.key,
    name: question.key,
    value: formik.values[question.key],
    onChange: formik.handleChange,
    className: classNames({ 'p-invalid': isFormFieldValid(question.key) }),
    showField,
  };
  let formField;
  switch (question.type) {
    case 'grid':
      return <GridField {...commonFieldProps} question={question} formik={formik} />;
    case 'select':
      formField = <Dropdown {...commonFieldProps} options={question.fieldOptions.selectOptions} />
      break;
    case 'yesno':
      formField = <div style={{ maxWidth: '120px' }}><SelectButton {...commonFieldProps} options={['Yes', 'No']} size="small" /></div>
      break;
    case 'numeric':
      formField = <InputNumber {...commonFieldProps} maxFractionDigits={2} onChange={undefined} onValueChange={formik.handleChange} />
      break;
    case 'upload':
      formField = <UploadField question={question} formik={formik} />
      break;
    case 'text-multi':
      formField = <InputTextarea {...commonFieldProps} rows={4} />
      break;
    case 'text-single':
    default:
      formField = <InputText {...commonFieldProps} />;
      break;
  }

  return (
    <div className="field" style={{ display: showField ? 'block' : 'none' }}>
      <span className="p-input-icon-right">
        <label
          htmlFor={question.key}
          className={classNames({ 'p-error': isFormFieldValid(question.key), 'font-bold': true })}>
          { question.text }
        </label>
        { formField }
      </span>
    </div>
  );
};

export default QuestionFields;