import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Link } from 'react-router-dom';
import { API_BASE, API_JWT_AUD } from '../api-config';
import Loading from './Loading';
import NewApplicationButton from './NewApplicationButton';

const columnStatusTemplate = app => {
  return app.completed
    ? <>Submitted - <Link to={`/app/view/${app.id}`}>View application</Link></>
    : <>In progress - <Link to={`/app/${app.id}`}>Resume application</Link></>;
};

const columnCreatedAtTemplate = app => {
  return app.created_at ? (new Date(app.created_at)).toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}) : '';
};

const columnUpdatedAtTemplate = app => {
  return app.updated_at ? (new Date(app.updated_at)).toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}) : '';
};

const HomePage = () => {
  const { user, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [ error, setError ] = useState();
  const [ applications, setApplications ] = useState();
  const [ loadingApplications, setLoadingApplications ] = useState(false);

  useEffect(() => {
    const getUserApplications = async () => {
      setApplications([]);
      setLoadingApplications(true);
      if (!user?.sub) {
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: API_JWT_AUD,
          }
        });

        const res = await fetch(`${API_BASE}/user_applications/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!res.ok) {
          throw new Error((await res.json())?.error ?? res.statusText);
        }
        setApplications(await res.json());
        setLoadingApplications(false);
      }
      catch (e) {
        setError(`Error loading applications: ${e.message}`);
        setLoadingApplications(false);
      }
    };
    getUserApplications();
  }, [ getAccessTokenSilently, setError, user?.sub ]);

  return (
    <>
      <Card>
        <div className="grid">
          <div className="col-9 flex justify-content-left">
            <div><img src="/tip_logo.png" alt="Texas Infrastructure Program Logo" width="150" style={{paddingRight: '20px', verticalAlign: 'middle'}} /></div>
            <h2 className="mt-2">Developer Application</h2>
          </div>
        </div>
        {
          error && (
            <Message severity="error" text={error} className="w-full justify-content-start" />
          )
        }
        {
          isAuthenticated && (
            <>
              <NewApplicationButton /> 
              {
                loadingApplications && <Loading />
              }
              {
                !loadingApplications && (
                  <DataTable value={applications} className="mt-2" sortField="updated_at" sortOrder={-1}>
                    <Column field="data.company_name" header="Company Name" sortable></Column>
                    <Column field="data.project_name" header="Project Name" sortable></Column>
                    <Column field="created_at" header="Created" body={columnCreatedAtTemplate} sortable dataType="date"></Column>
                    <Column field="updated_at" header="Last Updated" body={columnUpdatedAtTemplate} sortable dataType="date"></Column>
                    <Column field="completed" header="Status" body={columnStatusTemplate} sortable></Column>
                  </DataTable>
                )
              }
            </>
          )
        }
        {
          !isAuthenticated && !isLoading && (
            <div className="text-center">
              <Button onClick={() => loginWithRedirect()} severity="primary">Log In / Sign Up</Button>
            </div>
          )
        }
      </Card>
    </>
  );
};

export default HomePage;
