import { useEffect, useState } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { Message } from 'primereact/message';
import { API_BASE, API_JWT_AUD } from '../api-config';
import AppForm from './AppForm';
import Loading from './Loading';

const AppPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { appId } = useParams();
  const [savedApp, setSavedApp] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const loadSavedFormData = async () => {
      
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: API_JWT_AUD,
          }
        });

        const res = await fetch(`${API_BASE}/applications/${appId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!res.ok) {
          throw new Error((await res.json())?.error ?? res.statusText);
        }
        const app = await res.json();
        console.log('loaded saved application', app);
        setSavedApp(app);
      }
      catch (e) {
        setError(`Error loading application: ${e.message}`);
      }
    };

    if (appId && (!savedApp || savedApp.id !== appId)) {
      console.log('app id', appId);
      loadSavedFormData();
    }
  }, [appId, getAccessTokenSilently, setError, savedApp]);

  return (
    <>
      {
        error && (
          <Message severity="error" text={error} className="w-full justify-content-start" />
        )
      }
      {
        appId &&
          (savedApp ? <AppForm savedApp={savedApp} /> : <Loading />)
      }
      {
        !appId && <AppForm />
      }
    </>
  );
};

export default withAuthenticationRequired(AppPage, {
  onRedirecting: () => <div>Loading...</div>,
});