import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import debounce from 'just-debounce-it';
import { API_BASE, API_JWT_AUD } from '../api-config';
import questions from '../questions';
import FormUtil from '../util/FormUtil';
import CategoryQuestionList from './CategoryQuestionList';
import SubmitAppButton from './SubmitAppButton';
// import SaveAndContinueModal from './SaveAndContinueModal';
// import supabase from '../util/supabase';

const AppForm = ({ savedApp }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);

  const formik = useFormik({
    initialValues: FormUtil.initialValuesFromQuestions(questions.questions, savedApp?.data), // TODO: figure out why this is being called on re-render
    onSubmit: async values => {
      console.log('submit event', values);
      // setSaveError(undefined);
      // setSaving(true);
      try {
        if (!savedApp?.id) {
          throw new Error('Missing application ID');
        }

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: API_JWT_AUD,
          }
        });

        const res = await fetch(`${API_BASE}/applications/${savedApp?.id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            data: values,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
        });
        if (!res.ok) {
          throw new Error((await res.json())?.error ?? res.statusText);
        }
        console.log('saving res', (await res.json()));
        toast.current.show({ severity: 'success', summary: 'Progress saved', life: 1000 });
      }
      catch (e) {
        toast.current.show({ severity: 'error', summary: 'Error saving data', detail: e.message, sticky: true });
        // setSaveError(`Error saving data: ${e.message}`);
      }
      // setSaving(false);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(
      () => {
        console.log('debounce event');
        formik.submitForm();
      },
      1000
    ), [formik.submitForm]);

  useEffect(() => {
    // if (isEqual(formik.values, savedApp?.data)) {
    //   return;
    // }
    debouncedSubmit();
  }, [debouncedSubmit, formik.values, savedApp?.data]);

  // const handleFormValueChange = useCallback(values => {
  //   return debounce(() => {
  //     console.log('formik values changed', values);
  //   }, 1000);
  // });

  // useEffect(() => {
  //   handleFormValueChange(formik.values);
  // }, [formik.values, handleFormValueChange]);

  const footer = (
    <div className="flex justify-content-between">
      <div className="w-6">
        {
          (activeIndex > 0) && (
            <Button
              severity="secondary"
              onClick={() => {
                window.scrollTo(0, 0);
                setActiveIndex(activeIndex - 1);
              }}
              label={`Back to ${ questions.categories[activeIndex - 1].label }`}
              icon="fa fa-caret-left" />
          )
        }
      </div>
      <div className="w-6 flex justify-content-end">
        {
          (activeIndex < questions.categories.length - 1) && (
            <Button
              severity="info"
              onClick={() => {
                window.scrollTo(0, 0);
                setActiveIndex(activeIndex + 1);
              }}
              label={`Continue to ${ questions.categories[activeIndex + 1].label }`}
              icon="fa fa-caret-right"
              iconPos="right" />
          )
        }
        {
          (activeIndex === questions.categories.length - 1) && (
            <SubmitAppButton formik={formik} appId={savedApp?.id} />
          )
        }
      </div>
    </div>
  );
  return (
    <>
      <Toast ref={toast} />
      <Card footer={footer}>
        <div className="grid">
          <div className="col-9 flex justify-content-left">
            <div><img src="/tip_logo.png" alt="Texas Infrastructure Program Logo" width="150" style={{paddingRight: '20px', verticalAlign: 'middle'}} /></div>
            <h2 className="mt-2">Developer Application</h2>
          </div>
          <div className="col-3 text-right">
            <Link to="/"><Button severity="secondary" label="View Applications" /></Link>
          </div>
        </div>
        <div className="mb-5">
          <Steps model={questions.categories} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid mt-5">
          {
            questions.categories.map((c, i) => (
              <div style={{ display:  i === activeIndex ? 'block' : 'none', width: '80%', margin: 'auto' }} key={c.key}>
                <CategoryQuestionList category={c} formik={formik} questions={questions.questions} />
              </div>
            ))
          }          
        </form>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(AppForm, {
  onRedirecting: () => <div>Loading...</div>,
});
