import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';

const CompletedPage = () => {
  return (
    <Card>
      <div className="grid">
        <div className="col-9 flex justify-content-left">
          <div><img src="/tip_logo.png" alt="Texas Infrastructure Program Logo" width="150" style={{paddingRight: '20px', verticalAlign: 'middle'}} /></div>
          <h2 className="mt-2">Developer Application</h2>
        </div>
      </div>
      <div className="width: 60%; margin: auto;">
        <h3>Thank you for submitting your Texas Infrastructure Program Developer Application. The TIP team will review and get back to you if we have any questions.</h3>
        <Link to="/"><Button severity="secondary" label="View Applications" /></Link>
      </div>
    </Card>
  );
};

export default CompletedPage;
