import { Divider } from 'primereact/divider';
import QuestionFields from './QuestionFields';

const SectionQuestionList = ({ section, questions, formik }) => {
  const sectionQuestions = questions.filter(q => q.section === section);
  return (
    <div>
      {
        section && (
          <Divider type="dotted" key={`divider-${section}`} align="left">
            <span className="p-tag p-tag-info text-lg">{ section }</span>
          </Divider>
        )
      }
      { sectionQuestions.map(q => <QuestionFields question={q} formik={formik} key={q.key} />) }
    </div>
  );
};

const CategoryQuestionList = ({ category, questions, formik }) => {
  questions = questions.filter(q => q.category === category.key);

  const sections = questions.reduce((carry, q) => {
    if (carry.indexOf(q.section) < 0) {
      carry.push(q.section);
    }
    return carry;
  }, []);

  return (
    <div key={category.key}>
      {
        sections.map((section, i) => <SectionQuestionList key={i} section={section} questions={questions} formik={formik} />)
      }
    </div>
  );
};

export default CategoryQuestionList;
