import { useCallback } from 'react';
import { Button } from 'primereact/button';

const UploadedFileListing = ({ file, formik, questionKey }) => {
  const handleViewClick = useCallback(e => {
    e.preventDefault();
    console.log('view', file.name);
    const publicUrl = file.location.replace('/v1/object/', '/v1/object/public/');
    window.open(publicUrl);
    // console.log(supabase.storage.from(SUPABASE_STORAGE_BUCKET).getPublicUrl(file.location));
  }, [file.name, file.location]);

  const handleDeleteClick = useCallback(e => {
    e.preventDefault();
    // TODO: save to persistent storage
    formik.handleChange({
      target: {
        name: questionKey,
        value: (formik.values?.[questionKey] ?? []).filter(f => f.location !== file.location)
      },
    });
  }, [formik, file.location, questionKey]);

  return (
    <div className="flex justify-content-left mb-1">
      <Button label="Delete" icon="fa fa-trash" style={{ width: '20%' }} size="small" className="p-1 mr-1" onClick={handleDeleteClick} />
      <Button label={file.name} link onClick={handleViewClick} className="text-left p-0" />
    </div>
  );
};

export default UploadedFileListing;
