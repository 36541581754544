import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-balham.css";

const GridDisplay = ({ question, value }) => {
  const [rowData] = useState(value ?? []);

  const columnDefs = question.fieldOptions.columnDefs.map(cd => ({
    ...cd,
    editable: false,
  }));

  return (
    <>
      <label
        htmlFor={question.key}
        className={{ 'font-bold': true }}>
        { question.text }
      </label>
      <div id={question.key} className="ag-theme-balham mb-3" style={{ height: question.fieldOptions?.tableHeight || 300, width: question.fieldOptions?.tableWidth || '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs} />
      </div>
    </>
    
  );
};

export default GridDisplay;
