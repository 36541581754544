import { useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useCallback } from 'react';
import { API_BASE, API_JWT_AUD } from '../api-config';

const SubmitAppButton = ({ formik, appId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saving, setSaving] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    const submitAndComplete = async () => {
      setSaving(true);
      try {
        if (!appId) {
          throw new Error('Missing application ID');
        }

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: API_JWT_AUD,
          }
        });

        // TODO: clean up into separate function
        const res = await fetch(`${API_BASE}/applications/${appId}`, {
          method: 'PATCH',
          body: JSON.stringify({
            data: formik.values,
            completed: true,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
        });
        if (!res.ok) {
          throw new Error((await res.json())?.error ?? res.statusText);
        }
        console.log('completed saving res', (await res.json()));
        navigate('/completed');
      }
      catch (e) {
        toast.current.show({ severity: 'error', summary: 'Error saving data', detail: e.message, sticky: true });
        // setSaveError(`Error saving data: ${e.message}`);
      }
      setSaving(false);
    };

    submitAndComplete();
  }, [formik.values, appId, getAccessTokenSilently, navigate]);

  return (
    <>
      <Toast ref={toast} />
      <Button label="Submit" severity="success" icon="fa fa-check" iconPos="right" onClick={handleClick} loading={saving} />
    </>
  );
};

export default SubmitAppButton;
