import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';
import { Steps } from 'primereact/steps';
import { API_BASE, API_JWT_AUD } from '../api-config';
import questions from '../questions';
import GridDisplay from './GridDisplay';
import Loading from './Loading';

const numberFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });

const SectionList = ({ section, questions, data }) => {
  const sectionQuestions = questions.filter(q => q.section === section);
  return (
    <div>
      {
        section && (
          <Divider type="dotted" key={`divider-${section}`} align="left">
            <span className="p-tag p-tag-info text-lg">{ section }</span>
          </Divider>
        )
      }
      {
        sectionQuestions.map((q, i) => {
          if (q.type === 'grid') {
            return <div key={i}><GridDisplay value={data?.[q.key]} question={q} /></div>
          }
          else {
            return (
              <p key={i}>
                <strong>{ q.text }</strong><br />
                {
                  q.type === 'numeric' &&  typeof data?.[q.key] != 'undefined' && data?.[q.key] !== null
                    ? numberFormat.format(parseFloat(data?.[q.key]))
                    : data?.[q.key]
                }
              </p>
            );
          }
        })
      }
    </div>
  );
};

const CategoryList = ({ category, questions, data }) => {
  questions = questions.filter(q => q.category === category.key);

  const sections = questions.reduce((carry, q) => {
    if (carry.indexOf(q.section) < 0) {
      carry.push(q.section);
    }
    return carry;
  }, []);

  return (
    <div key={category.key}>
      {
        sections.map((section, i) => <SectionList key={i} section={section} questions={questions} data={data} />)
      }
    </div>
  );
};

const ViewAppPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { appId } = useParams();
  const [savedApp, setSavedApp] = useState();
  const [loadingApp, setLoadingApp] = useState();
  const [error, setError] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const loadSavedFormData = async () => {
      setLoadingApp(true);
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: API_JWT_AUD,
          }
        });

        const res = await fetch(`${API_BASE}/applications/${appId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!res.ok) {
          throw new Error((await res.json())?.error ?? res.statusText);
        }
        const app = await res.json();
        setSavedApp(app);
      }
      catch (e) {
        setError(`Error loading application: ${e.message}`);
      }
      setLoadingApp(false);
    };

    if (appId && (!savedApp || savedApp.id !== appId)) {
      loadSavedFormData();
    }
  }, [appId, getAccessTokenSilently, setError, savedApp]);

  return (
    <>
      {
        loadingApp && <Loading />
      }
      {
        !loadingApp && (
          <Card>
            <div className="grid">
              <div className="col-9 flex justify-content-left">
                <div><img src="/tip_logo.png" alt="Texas Infrastructure Program Logo" width="150" style={{paddingRight: '20px', verticalAlign: 'middle'}} /></div>
                <h2 className="mt-2">View Developer Application: { savedApp?.data?.company_name } - { savedApp?.data?.project_name }</h2>
              </div>
              <div className="col-3 text-right">
                <Link to="/"><Button severity="secondary" label="View Applications" /></Link>
              </div>
            </div>
            { error && <Message severity="error" text={error} className="w-full justify-content-start" /> }
            <div className="mb-5">
              <Steps model={questions.categories} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
            </div>
            {
                questions.categories.map((c, i) => (
                  <div style={{ display:  i === activeIndex ? 'block' : 'none', width: '80%', margin: 'auto' }} key={c.key}>
                    <CategoryList category={c} questions={questions.questions} data={savedApp?.data} />
                  </div>
                ))
              }
          </Card>
        )
      }
    </>
  );
};

export default ViewAppPage;
