import { useCallback, useState } from 'react';
import { classNames } from 'primereact/utils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-balham.css";

const GridField = ({ question, formik, showField }) => {
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const handleCellValueChanged = useCallback(event => {
    // const newRowData = event.api.clientSideRowModel.rowsToDisplay.map(r => r.data);
    const newRowData = [];
    event.api.forEachNode(node => {
      newRowData.push(node.data);
    });
    formik.handleChange({
      target: {
        name: question.key,
        // value: JSON.stringify(newRowData),
        value: newRowData,
      },
    });
    console.log('new row data', newRowData, formik.values, event);
  }, [question, formik]);

  const [rowData] = useState(formik.values[question.key] ?? (question.defaultValue) ?? []);
  // console.log('grid', (formik.values[question.key] ?? formik.values[question.key]) ?? []);

  if (showField === undefined) {
    showField = true;
  }

  return (
    <div className="field" style={{ display: showField ? 'block' : 'none' }}>
      <label
        htmlFor={question.key}
        className={classNames({ 'p-error': isFormFieldValid(question.key), 'font-bold': true, 'mb-2': true })}>
        { question.text }
      </label>
      <div id={question.key} className="ag-theme-balham mb-3" style={{ height: question.fieldOptions?.tableHeight || 300, width: question.fieldOptions?.tableWidth || '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={question.fieldOptions.columnDefs}
          onCellValueChanged={handleCellValueChanged} />
      </div>
    </div>
    
  );
};

export default GridField;
