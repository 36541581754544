const initialValuesFromQuestions = (questions, savedFormData = undefined) => {
  const defaultValuesForTypes = {
    'text-single': '',
    'text-multi': '',
    'numeric': '',
  };

  // const localFormValues = JSON.parse(localStorage.getItem('formValues'));
  
  return questions.reduce((carry, q) => {
    carry[q.key] = savedFormData?.[q.key] ?? (q.defaultValue ?? defaultValuesForTypes[q.type]);
    return carry;
  }, {});
};

const FormUtil = {
  initialValuesFromQuestions,
};

export default FormUtil;
