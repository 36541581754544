import { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { API_BASE, API_JWT_AUD } from '../api-config';

const NewApplicationButton = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loadingApp, setLoadingApp] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    const createAppAndRedirect = async () => {
      setLoadingApp(true);
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: API_JWT_AUD,
          }
        });

        const res = await fetch(`${API_BASE}/applications/`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
        });
        if (!res.ok) {
          throw new Error((await res.json())?.error ?? res.statusText);
        }
        const app = await res.json();
        navigate(`/app/${app.id}`);
      }
      catch (e) {
        setError(`Error creating application: ${e.message}`);
      }
      setLoadingApp(false);
    };

    createAppAndRedirect();
  }, [getAccessTokenSilently, navigate]);

  return (
    <>
      <Button severity="info" label="Start new application" icon="fa fa-plus" onClick={handleClick} loading={loadingApp} />
      { error && <Message severity="error" text={error} className="w-full justify-content-start" /> }
    </>
  );
};

export default NewApplicationButton;
