import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/tailwind-light/theme.css';
import 'primeflex/primeflex.css';
import { LicenseManager } from  'ag-grid-enterprise';
import './App.css';
import { Route, Routes } from 'react-router';
import AppPage from './components/AppPage';
import CompletedPage from './components/CompletedPage';
import HomePage from './components/HomePage';
import ViewAppPage from './components/ViewAppPage';

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-046018 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Blx Group LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 October 2024 )____[v2]_MTcyODg2MDQwMDAwMA==c2b9a4f5dc6f8a5e316a1c6db6944135");

function App() {
  return (
    <Routes>
      <Route path="/app/view/:appId?" element={<ViewAppPage />} />
      <Route path="/app/:appId?" element={<AppPage />} />
      <Route path="/completed/" element={<CompletedPage />} />
      <Route path="/" element={<HomePage />}></Route>
    </Routes>
  )
}

export default App;
