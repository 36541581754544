import { createClient } from "@supabase/supabase-js";


export const SUPABASE_PROJECT_ID = "yxcjvknhbrmnfiergdug";
export const SUPABASE_ANON_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl4Y2p2a25oYnJtbmZpZXJnZHVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTkzMTY2ODYsImV4cCI6MjAxNDg5MjY4Nn0.Jp5j1tITVASq2r77PkdLWqJJc13HUz4Au8x_MLkIthk";
export const SUPABASE_STORAGE_URL = `https://${SUPABASE_PROJECT_ID}.supabase.co/storage/v1/object`;
export const SUPABASE_STORAGE_BUCKET = 'application-uploads';

export const supabase = createClient(
  `https://${SUPABASE_PROJECT_ID}.supabase.co`,
  SUPABASE_ANON_KEY,
);

export default supabase;
