import { useCallback, useEffect } from 'react';
import Uppy from '@uppy/core';
import XHR from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import { v4 as uuidv4 } from 'uuid';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import { SUPABASE_ANON_KEY, SUPABASE_STORAGE_URL, SUPABASE_STORAGE_BUCKET } from '../util/supabase';
import UploadedFileListing from './UploadedFileListing';

const uppy = new Uppy();
uppy.use(XHR, {
  endpoint: SUPABASE_STORAGE_URL,
  headers: {
    authorization: `Bearer ${SUPABASE_ANON_KEY}`,
    apikey: SUPABASE_ANON_KEY,
  },
  allowedMetaFields: ['bucketName', 'objectName', 'contentType', 'cacheControl'],
  onError: function (error) {
    console.error('Failed because: ' + error)
  },
});

uppy.on('file-added', (file) => {
  uppy.setFileState(file.id, {
    xhrUpload: {
      ...file.xhrUpload,
      endpoint: `${SUPABASE_STORAGE_URL}/${SUPABASE_STORAGE_BUCKET}/${uuidv4()}.${encodeURIComponent(file.meta.name.split('.').pop())}`, // TODO: add file name hash or something
    },
  });
  // const supabaseMetadata = {
  //   bucketName: SUPABASE_STORAGE_BUCKET,
  //   objectName: file.name,
  //   contentType: file.type,
  // }

  // file.meta = {
  //   ...file.meta,
  //   ...supabaseMetadata,
  // }

  console.log('file added', file);
});

const UploadField = ({ question, formik, readOnly }) => {
  const handleUppyComplete = useCallback(result => {
    if (question.key) {
        let files = (formik.values[question.key] ?? []);
        files = files.concat(result.successful.map(r => ({ location: r.xhrUpload?.endpoint,  name: r.name })));
        formik.handleChange({
          target: {
            name: question.key,
            value: files,
          },
        });
    }
  }, [question.key, formik]);

  useEffect(() => {
    uppy.off('complete', null).on('complete', handleUppyComplete);
  }, [handleUppyComplete]);

  const existingFiles = formik?.values[question.key] ?? [];

  return (
    <div className="mb-3">
      <div className="grid">
        {
          !readOnly && (
            <div className="col-8">
              <Dashboard uppy={uppy} height={175} id={question.key} />
            </div>
          )
        }
        <div className="col-4">
          { !readOnly && <h4>Already Uploaded:</h4> }
          {
            existingFiles.map(file => (
              <UploadedFileListing
                file={file}
                key={file.location}
                formik={formik}
                questionKey={question.key} />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default UploadField;
