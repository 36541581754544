import { ProgressSpinner } from "primereact/progressspinner"

const Loading = ({ width, height, strokeWidth, align, message }) => {
  return (
    <div className={`text-${align ?? 'center'}`}>
      <ProgressSpinner
        style={{width: width ?? '50px', height: height ?? '50px'}}
        strokeWidth={strokeWidth ?? "8"}
        fill="var(--surface-ground)" animationDuration=".5s" />
      { message }
    </div>
  );
};

export default Loading;
